import * as React from 'react';

import {Box, Button, Grid, Step, Divider, Stepper} from "@mui/material";
import Typography from '@mui/material/Typography';

import StepChange from "./../Steps/StepChange";
import StepChallenge from "./../Steps/StepChallenge";
import StepCompetence from "./../Steps/StepCompetence";
import StepCommunity from "./../Steps/StepCommunity";
import Results from "./../Steps/Results";

const steps = ["change", "challenge", "competence", "community"];

function getStepContent(step, handleChange, values, results) {
    switch (step) {
        case 1:
            return <StepChange values={values} handleChange={handleChange}/>;
        case 2:
            return <StepChallenge values={values} handleChange={handleChange}/>;
        case 3:
            return <StepCompetence values={values} handleChange={handleChange}/>;
        case 4:
            return <StepCommunity values={values} handleChange={handleChange}/>;
        case 5:
            return <Results results={results}/>;
        default:
            throw new Error('Unknown step');
    }
}

function Questionnaire() {
    const stepTitles = [
        "Zum CD der Stadt Zürich",
        "Farben sind Emotionen",
        "Basiswerte aus Workshop",
        "Orientierung und Inputs",
        "Empfehlungen und Raster",
        "Gut zur Kommunkation",
    ];
    const stepTexts = [
        "Das Corporate Design der Stadt Zürich bietet das ganze Spektrum von Farben zur Nutzung an.",
        "Jede Farbe spricht bestimmte Emotionen an. Welche Farben stützen und verstärken Informationen und Botschaften? Was für Bilder passen dazu? Worauf reagieren Zielgruppe aufmerksam und zustimmend?",
        "Die Basiswerte mit der passenden Zuordnung von Farben, Bildern und Themen hat die HFRB in verschiedenen Workshops entwickelt.",
        "Die MATCH BOX unterstützt das Marketing, die Gestaltung und die Redaktionen sowie die Dozentinnen und Dozenten bei der Kreation und Realisation von Kommunikation. Sie schlägt passende Farben und Bildern sowie ein wirksames Wording und Storytelling vor.",
        "Für die Power Point-Präsentation erstellt MATCH BOX automatisch ein Raster. Ebenso ergeben sich Empfehlungen zu Farben, Bildern und Themen für Unterrichtsmittel und Dokumentationen sowie das Storytelling in Magazinen sowie in den Social-Media.",
        "",
    ]

    const [answerAllHint, setAnswerAllHint] = React.useState("");
    const [activeStep, setActiveStep] = React.useState(0);
    const [activeStepText, setActiveStepText] = React.useState(stepTexts[0]);
    const [activeStepTitle, setActiveStepTitle] = React.useState(stepTitles[0]);
    const [result, setResult] = React.useState({
        change: 3 * 5,
        challenge: 3 * 5,
        competence: 3 * 5,
        community: 3 * 5
    });


    const [values, setValues] = React.useState({
        change: [3, 3, 3, 3, 3],
        challenge: [3, 3, 3, 3, 3],
        competence: [3, 3, 3, 3, 3],
        community: [3, 3, 3, 3, 3],
    });

    const handleStart = () => {
        //reset votes
        Object.entries(values).forEach(([cat, data]) => {
            Object.entries(data).forEach(([lbl, num]) => {
                values[cat][lbl] = 3;
            });
        });
        setValues(values);
        setStep(0);
    };

    const getRandNum = () => {
        let min = 1;
        let max = 5;
        return Math.floor(Math.random() * (max - min + 1) + min);
    }

    const handleTest = () => {
        let values = {
            change: [getRandNum(), getRandNum(), getRandNum(), getRandNum(), getRandNum()],
            challenge: [getRandNum(), getRandNum(), getRandNum(), getRandNum(), getRandNum()],
            competence: [getRandNum(), getRandNum(), getRandNum(), getRandNum(), getRandNum()],
            community: [getRandNum(), getRandNum(), getRandNum(), getRandNum(), getRandNum()],
        };
        setValues(values);
        recalculateTotals();
        setStep(5);
    }

    const setStep = (x) => {
        setActiveStep(x);
        setActiveStepText(stepTexts[x]);
        setActiveStepTitle(stepTitles[x]);
    }

    const handleChange = (category, num, value) => {
        values[category][num] = value;
        setValues(values);
        recalculateTotals();
    }

    const recalculateTotals = () => {
        const totals = {};

        Object.entries(values).forEach(([cat, data]) => {
            Object.entries(data).forEach(([lbl, num]) => {
                if (typeof totals[cat] === 'undefined') {
                    totals[cat] = num;
                } else {
                    totals[cat] += num;
                }
            });
        });

        setResult(totals);
        setAnswerAllHint("");
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={4}>
                <Typography variant="h1">Match<br/>Box</Typography>
                <Typography style={{lineHeight: 1.1, textDecoration: 'underline'}} gutterBottom>{activeStepTitle}</Typography>
                <Typography style={{lineHeight: 1.1}} gutterBottom>{activeStepText}</Typography>
                <Divider variant="bold"/>
                {activeStep < 5 && (
                    <Stepper activeStep={activeStep} sx={{pt: 3, pb: 5}}>
                        {steps.map((label, index) => (
                            <Step key={index} classes={{root: "step-" + label + (index + 1 === activeStep ? " step-active" : "")}}>
                                <Typography onClick={() => setStep(index + 1)}>
                                    {index + 1}
                                </Typography>
                            </Step>
                        ))}
                    </Stepper>
                )}

                {activeStep === 5 && (
                    <Button variant="contained" onClick={handleStart} sx={{mt: 3, ml: 1}}>
                        neu starten
                    </Button>
                )}

                <Button variant="contained" onClick={handleTest} sx={{mt: 3, ml: 1}}>
                    Test mit Zufallszahlen
                </Button>

            </Grid>
            <Grid item xs={1}></Grid>
            <Grid item xs={7}>
                {activeStep === 0 ? (
                    <React.Fragment>
                        <Typography variant="h2">Die MATCH BOX ermittelt passende Farben, aber auch Bilder und Themen. Das aufgrund der Beurteilung von Inhalten und Kommunikationszielen vor dem Hintergrund der Basiswerte der HFRB.</Typography>
                        <Divider/>
                        <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
                            <Button variant="contained" onClick={() => setStep(activeStep + 1)}>Start</Button>
                        </Box>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        {getStepContent(activeStep, handleChange, values, result)}
                        {activeStep < 5 && (
                            <>
                                <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                                    <Button variant="outlined" onClick={() => setStep(activeStep - 1)} sx={{mt: 3, ml: 1}}>
                                        zurück
                                    </Button>
                                    <Button
                                        variant="contained"
                                        onClick={() => setStep(activeStep + 1)}
                                        sx={{mt: 3, ml: 1}}
                                    >
                                        {activeStep === 4 ? 'Auswertung' : 'nächster Schritt'}
                                    </Button>
                                </Box>
                                <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
                                    <Typography sx={{color: 'red'}}>{answerAllHint}</Typography>
                                </Box>
                            </>
                        )}
                    </React.Fragment>
                )}
            </Grid>
        </Grid>
    );
}

export default Questionnaire;
