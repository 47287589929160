import * as React from 'react';
import Typography from '@mui/material/Typography';
import {Divider} from "@mui/material";
import StepSlider from "../Components/StepSlider";

export default function StepCommunity(props) {
    return (
        <React.Fragment>
            <Typography variant="h2">
                Basiswert COMMUNITY. Wir von der HFRB sind bedarfsorientiert, menschlich, partnerschaftlich.
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
                Das Unterrichtsmittel, die Dokumentation oder das Storytelling:
            </Typography>

            <Divider variant="medium"/>

            <StepSlider {...props} category="community" index={0} label="befasst sich mit Themen zur Zusammenarbeit"/>
            <StepSlider {...props} category="community" index={1} label="appelliert an das Gemeinschaftsgefühl"/>
            <StepSlider {...props} category="community" index={2} label="sensibilisiert für soziale Aspekte"/>
            <StepSlider {...props} category="community" index={3} label="verbessert kommunikative Fähigkeiten"/>
            <StepSlider {...props} category="community" index={4} label="fragt nach dem Nutzen und den Emotionen der Bevölkerung."/>

            <Divider variant="medium"/>
        </React.Fragment>
    )
}