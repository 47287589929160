import * as React from 'react';
import Typography from '@mui/material/Typography';
import StepSlider from "../Components/StepSlider";
import {Divider} from "@mui/material";

export default function StepChallenge(props) {
    return (
        <React.Fragment>
            <Typography variant="h2">
                Basiswert CHALLENGE. Wir von der HFRB sind überzeugend, leidenschaftlich, bewegend.
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
                Das Unterrichtsmittel, die Dokumentation oder das Storytelling:
            </Typography>

            <Divider variant="medium"/>

            <StepSlider {...props} category="challenge" index={0} label="bewegt mit Emotionen und weckt Leidenschaft"/>
            <StepSlider {...props} category="challenge" index={1} label="thematisiert Leistungsaspekte und Wettbewerb"/>
            <StepSlider {...props} category="challenge" index={2} label="verspricht Erfolge auf der Laufbahn"/>
            <StepSlider {...props} category="challenge" index={3} label="zeigt die Attraktivität der Ausbildung und Beruf auf"/>
            <StepSlider {...props} category="challenge" index={4} label="überzeugt mit Erfolgsgeschichten von Persönlichkeiten, Teams und Organisationen"/>

            <Divider variant="medium"/>
        </React.Fragment>
    )
}