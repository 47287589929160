import React from 'react';
import {Stack, Slider, Button, Typography, Grid} from "@mui/material";

const StepSlider = (props) => {
    const value = props.values[props.category][props.index];

    const handleChange = (event, newValue) => {
        if (typeof newValue === 'number') {
            props.handleChange(props.category, props.index, newValue);
        }
    };

    const handleNext = () => {
        if (value >= 5) return;
        handleChange(null, Math.floor(value) + 1);
    };

    const handleBack = () => {
        if (value <= 1) return;
        handleChange(null, Math.ceil(value) - 1);
    };

    return (
        <Grid container spacing={2} marginBottom={5}>
            <Grid item xs={7}>
                <Typography>
                    {props.label}
                </Typography>
            </Grid>
            <Grid item xs={5}>
                <Stack direction="row">
                    <Button variant="text" onClick={handleBack}>-</Button>
                    <Slider color={props.category} value={value} onChange={handleChange} defaultValue={props.values[props.category][props.index]} step={1} min={1} max={5}/>
                    <Button variant="text" onClick={handleNext}>+</Button>
                </Stack>
            </Grid>
        </Grid>
    )
}

export default StepSlider;