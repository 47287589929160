import * as React from 'react';

import {Container, ThemeProvider, createTheme} from "@mui/material";
import Questionnaire from "./Components/Questionnaire";

const theme = createTheme({
    typography: {
        fontFamily: 'HelveticaNeue, sans-serif',
        fontSize: 16,
        body1: {
            color: '#333',
        },
        h1: {
            fontWeight: 'bold',
            fontSize: '84px',
            lineHeight: 0.8,
            marginBottom: 20,
            textTransform: 'uppercase',
            color: '#333',
        },
        h2: {
            fontWeight: 'bold',
            fontSize: '21px',
            color: '#333',
        },
        h3: {
            fontSize: '21px',
        },
        subtitle1: {
            fontStyle: 'italic'
        },
        button: {
            textTransform: 'none',
            fontSize: 18,
        }
    },
    palette: {
        primary: {
            main: '#000'
        },
        change: {
            main: '#FAC616'
        },
        challenge: {
            main: '#E8482B'
        },
        competence: {
            main: '#428439'
        },
        community: {
            main: '#153291'
        },
    },
    components: {
        MuiTableCell: {
           styleOverrides: {
               root: {
                   paddingLeft: 0
               }
           }
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: 25
                }
            },
            variants: [
                {
                    props: {variant: 'text'},
                    style: {
                        padding: 0,
                        lineHeight: 1
                    }
                }
            ]
        },
        MuiDivider: {
            styleOverrides: {
                root: {
                    borderColor: 'black',
                    margin: '40px 0',
                    borderBottomWidth: '3px'
                },
            },
            variants: [
                {
                    props: {variant: 'bold'},
                    style: {
                        borderBottomWidth: '15px'
                    }
                }
            ]
        },
        MuiStep: {
            styleOverrides: {
                root: {
                    // border: '1px solid grey',
                    // borderRadius: '50%',
                    // display: 'block',
                    // width: 26,
                    // height: 26
                },
            }
        }
    },
});

function App() {
    return (
        <ThemeProvider theme={theme}>
            <Container maxWidth="xl">
                <Questionnaire/>
            </Container>
        </ThemeProvider>
    );
}

export default App;
