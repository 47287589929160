import * as React from 'react';
import Typography from '@mui/material/Typography';
import StepSlider from "../Components/StepSlider";
import {Divider} from "@mui/material";

export default function StepChange(props) {
    return (
        <React.Fragment>
            <Typography variant="h2">
                Basiswert CHANGE. Wir von der HFRB sind kommunikativ, innovativ, inspirierend.
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
                Das Unterrichtsmittel, die Dokumentation oder das Storytelling:
            </Typography>

            <Divider variant="medium"/>

            <StepSlider {...props} category="change" index={0} label="überrascht mit unerwarteten Inputs und Positionen"/>
            <StepSlider {...props} category="change" index={1} label="befördert persönliche nachhaltige Veränderungen"/>
            <StepSlider {...props} category="change" index={2} label="regt gedanklich an und inspiriert"/>
            <StepSlider {...props} category="change" index={3} label="nutzt neuartige Lehrmittel und -methoden"/>
            <StepSlider {...props} category="change" index={4} label="ist verbunden mit Services"/>

            <Divider variant="medium"/>
        </React.Fragment>
    )
}