import * as React from 'react';
import {Typography, Box, Grid, Divider, Button, Link} from '@mui/material';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';

const titles = [
    "Primärfarbe", "Sekundärfarbe", "Schmuckfarbe", "Schmuckfarbe"
]
const colors = {
    change: {
        25: {name: 'Gelb', hex: '#f6ea3e', pantone: ''},
        20: {name: 'Gelb', hex: '#ecd72a', pantone: ''},
        15: {name: 'Gelb', hex: '#feca25', pantone: ''},
        10: {name: 'Gelb', hex: '#fcb828', pantone: ''},
        5: {name: 'Gelb', hex: '#bb811e', pantone: ''}
    },
    challenge: {
        25: {name: 'Rot', hex: '#f36922', pantone: ''},
        20: {name: 'Rot', hex: '#e41f24', pantone: ''},
        15: {name: 'Rot', hex: '#e9375c', pantone: ''},
        10: {name: 'Rot', hex: '#ef5968', pantone: ''},
        5: {name: 'Rot', hex: '#f38784', pantone: ''}
    },
    community: {
        25: {name: 'Blau', hex: '#323092', pantone: ''},
        20: {name: 'Blau', hex: '#3353a4', pantone: ''},
        15: {name: 'Blau', hex: '#4481c3', pantone: ''},
        10: {name: 'Blau', hex: '#51a5db', pantone: ''},
        5: {name: 'Blau', hex: '#2bc2eb', pantone: ''}
    },
    competence: {
        25: {name: 'Grün', hex: '#0b5756', pantone: ''},
        20: {name: 'Grün', hex: '#0f6936', pantone: ''},
        15: {name: 'Grün', hex: '#7ab93e', pantone: ''},
        10: {name: 'Grün', hex: '#b6cd35', pantone: ''},
        5: {name: 'Grün', hex: '#d6df3c', pantone: ''}
    }
};

const mapper = {
    change: {
        25: {
            community: {},
            challenge: {},
            competence: {}
        },
        20: {
            community: {},
            challenge: {},
            competence: {5: 10}
        },
        15: {
            community: {},
            challenge: {},
            competence: {5: 15, 10: 15}
        },
        10: {
            community: {},
            challenge: {5: 10},
            competence: {10: 15}
        },
        5: {
            community: {15: 20, 10: 5},
            challenge: {25: 20, 10: 5},
            competence: {15: 10}
        }
    },
    challenge: {
        25: {
            community: {15: 20, 10: 5},
            competence: {15: 10},
            change: {5: 10}
        },
        20: {
            community: {20: 25, 15: 10},
            competence: {25: 15, 20: 15},
            change: {5: 10}
        },
        15: {
            community: {20: 25, 15: 10},
            competence: {20: 25, 15: 10},
            change: {}
        },
        10: {
            community: {20: 25, 15: 10},
            competence: {20: 15},
            change: {5: 10}
        },
        5: {
            community: {15: 20, 10: 20, 5: 20},
            competence: {15: 20},
            change: {5: 10}
        }
    },
    community: {
        25: {
            change: {},
            challenge: {},
            competence: {25: 15, 20: 15}
        },
        20: {
            change: {},
            challenge: {20: 25, 15: 10},
            competence: {25: 15, 20: 15}
        },
        15: {
            change: {5: 10},
            challenge: {25: 5, 20: 5, 15: 5, 10: 5},
            competence: {15: 10}
        },
        10: {
            change: {5: 10},
            challenge: {5: 10},
            competence: {15: 10}
        },
        5: {
            change: {},
            challenge: {5: 10},
            competence: {15: 10}
        }
    },
    competence: {
        25: {
            community: {25: 15, 20: 15},
            challange: {20: 25},
            change: {}
        },
        20: {
            community: {25: 15, 20: 15},
            challange: {20: 25},
            change: {}
        },
        15: {
            community: {15: 20, 10: 20, 5: 20},
            challange: {5: 10},
            change: {5: 10}
        },
        10: {
            community: {5: 10},
            challenge: {},
            change: {10: 15}
        },
        5: {
            community: {},
            challenge: {},
            change: {20: 25, 15: 10}
        }
    }
}

export default function Results(props) {
    const result = JSON.parse(JSON.stringify(props.results));

    const sortable = Object.keys(result).sort(function (a, b) {
        return result[a] - result[b]
    }).reverse();

    //round results to 5
    // console.log("result", result);
    Object.entries(result).forEach(([key, total]) => {
        let num = Math.ceil(total / 5) * 5;
        if (num > 25) num = 25;
        if (num < 5) num = 5;
        result[key] = num;
    });
    // console.log("result", result);

    //mapper
    // console.log("sortable", sortable);

    let primary = sortable[0];
    let secondary = sortable[1];

    console.log("primary", primary, result[primary]);
    console.log("secondary", secondary, result[secondary]);

    if (mapper[primary] && mapper[primary][result[primary]]) {
        let source = mapper[primary][result[primary]];
        // console.log("source", source);
        if (source[secondary] && source[secondary][result[secondary]]) {
            let newvalue = source[secondary][result[secondary]];
            console.log("newvalue", newvalue);
            result[secondary] = newvalue;
        }
    }
    // console.log(mapper[sortable[0]][result[sortable[0]]][sortable[1][result[sortable[1]]]]);

    // if (mapper[sortable[0]][result[sortable[0]]]
    //     [sortable[1][result[sortable[1]]]]) {
    //     console.log()
    // }


    function hexToRgb(hex) {
        // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
        var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
        hex = hex.replace(shorthandRegex, function (m, r, g, b) {
            return r + r + g + g + b + b;
        });

        var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        return result ? [
            parseInt(result[1], 16),
            parseInt(result[2], 16),
            parseInt(result[3], 16)
        ] : null;
    }

    function hexToCMYK(hex) {
        var computedC = 0;
        var computedM = 0;
        var computedY = 0;
        var computedK = 0;

        hex = (hex.charAt(0) === "#") ? hex.substring(1, 7) : hex;

        if (hex.length !== 6) {
            alert('Invalid length of the input hex value!');
            return;
        }
        if (/[0-9a-f]{6}/i.test(hex) !== true) {
            alert('Invalid digits in the input hex value!');
            return;
        }

        var r = parseInt(hex.substring(0, 2), 16);
        var g = parseInt(hex.substring(2, 4), 16);
        var b = parseInt(hex.substring(4, 6), 16);

        // BLACK
        if (r === 0 && g === 0 && b === 0) {
            computedK = 1;
            return [0, 0, 0, 1];
        }

        computedC = 1 - (r / 255);
        computedM = 1 - (g / 255);
        computedY = 1 - (b / 255);

        var minCMY = Math.min(computedC, Math.min(computedM, computedY));

        computedC = Math.round(((computedC - minCMY) / (1 - minCMY)) * 100);
        computedM = Math.round(((computedM - minCMY) / (1 - minCMY)) * 100);
        computedY = Math.round(((computedY - minCMY) / (1 - minCMY)) * 100);
        computedK = Math.round(minCMY * 100);

        return [computedC, computedM, computedY, computedK];
    }

    function openTemplate(e) {
        const params = [
            "type=" + e.target.dataset.type
        ];
        const paramkeys = ["accent1", "accent2", "accent3", "accent4"];
        sortable.forEach((key, pos) => {
            const num = result[key];
            params.push(paramkeys[pos] + "=" + colors[key][num].hex.replace("#", ""));
        });

        const pptxLink = window.location.protocol + "//" + window.location.hostname + "/template.php?" + params.join("&");
        window.open(pptxLink, "_blank")
    }

    return (
        <React.Fragment>
            <Box sx={{backgroundColor: 'black'}}>
                <Grid container spacing={0}>
                    {sortable.map((key, pos) => {
                        let num = result[key];
                        // let num = Math.ceil(result[key] / 5) * 5;
                        // if (num > 25) num = 25;
                        // if (num < 5) num = 5;
                        return (
                            <Grid item xs={3}>
                                <Box sx={{padding: 3}}>
                                    <Link href={"#result-" + pos}>
                                        <Typography sx={{color: '#999', marginBottom: 1}}>{titles[pos]}</Typography>
                                        <Box sx={{backgroundColor: colors[key][num].hex, aspectRatio: '1/1', marginBottom: 1}}>
                                        </Box>
                                        <Box sx={{display: 'flex', justifyContent: 'center'}}>
                                            <ArrowDownwardIcon sx={{color: '#333', fontSize: 60}}/>
                                        </Box>
                                    </Link>
                                </Box>
                            </Grid>
                        )
                    })}
                </Grid>
            </Box>

            <Box sx={{paddingTop: 3, paddingBottom: 2}}>
                <Button variant="contained" onClick={openTemplate} data-type="ppt" target="_blank">Power Point Template Herunterladen</Button>
                &nbsp;
                <Button variant="contained" onClick={openTemplate} data-type="word" target="_blank">Word Template Herunterladen</Button>
            </Box>
            {sortable.map((key, pos) => {
                const num = result[key];
                const rgb = hexToRgb(colors[key][num].hex);
                const cmyk = hexToCMYK(colors[key][num].hex);
                return (
                    <Box sx={{paddingTop: 3, paddingBottom: 2}} id={"result-" + pos}>
                        <Typography variant="h2" sx={{marginBottom: 2}}>{titles[pos]}</Typography>
                        <Grid container spacing={2}>
                            <Grid item xs={5}>
                                <Box sx={{backgroundColor: colors[key][num].hex, aspectRatio: '1/1'}}>
                                </Box>
                            </Grid>
                            <Grid item xs={7}>
                                <Typography variant="h2" sx={{textTransform: 'uppercase'}}>{colors[key][num].name} {num}</Typography>
                                <TableContainer>
                                    <Table>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell>
                                                    RGB
                                                </TableCell>
                                                <TableCell>
                                                    {rgb.join(' | ')}
                                                </TableCell>
                                                <TableCell>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    HEX
                                                </TableCell>
                                                <TableCell>
                                                    {colors[key][num].hex}
                                                </TableCell>
                                                <TableCell>
                                                    <Button size="small" variant="outlined" onClick={() => {
                                                        navigator.clipboard.writeText(colors[key][num].hex)
                                                    }}>Kopieren</Button>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    CMYK
                                                </TableCell>
                                                <TableCell>
                                                    {cmyk.join(' | ')}
                                                </TableCell>
                                                <TableCell>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    PANTONE
                                                </TableCell>
                                                <TableCell>
                                                    {colors[key][num].pantone}
                                                </TableCell>
                                                <TableCell>
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                        </Grid>

                        <Divider sx={{marginBottom: 0}}/>
                    </Box>
                )
            })}
        </React.Fragment>
    )
}