import * as React from 'react';
import Typography from '@mui/material/Typography';
import {Divider} from "@mui/material";
import StepSlider from "../Components/StepSlider";

export default function StepCompetence(props) {
    return (
        <React.Fragment>
            <Typography variant="h2">
                Basiswert COMPETENCE. Wir von der HFRB sind zielorientiert, professionell, verantwortungsvoll.
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
                Das Unterrichtsmittel, die Dokumentation oder das Storytelling:
            </Typography>

            <Divider variant="medium"/>

            <StepSlider {...props} category="competence" index={0} label="vermittelt Fachwissen"/>
            <StepSlider {...props} category="competence" index={1} label="erhöht die handwerkliche Kompetenz"/>
            <StepSlider {...props} category="competence" index={2} label="sensibilisiert für Verantwortung"/>
            <StepSlider {...props} category="competence" index={3} label="befasst sich mit Sicherheitsaspekten"/>
            <StepSlider {...props} category="competence" index={4} label="ist mit Gewinn an Ansehen verbunden (Diplome etc.)."/>

            <Divider variant="medium"/>
        </React.Fragment>
    )
}